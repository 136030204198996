export default {
  data() {
    return {
      list: {
        url: '/user/getAllList',
        method: 'post',
        fields: this.getListFields()
      }
    };
  },
  methods: {
    getListFields() {
      return [
        {
          label: '姓名',
          prop: 'name',
          width: 120,
          showOverflowTooltip: true
        },
        {
          label: '部门',
          prop: 'department',
          showOverflowTooltip: true
        },
        { label: '手机号', prop: 'telephone', width: 160, align: 'right' },
        { label: '身份证号', prop: 'idCard', width: 230, align: 'right' },
        { label: '警号', prop: 'policeCard', width: 120, align: 'right' },
        { label: '性别', prop: 'sex', width: 80, align: 'center' },
        {
          label: '角色',
          prop: 'roleName',
          width: 120,
          showOverflowTooltip: true
        },
        {
          label: '最近登录',
          prop: 'lastLoginTime',
          width: 220,
          showOverflowTooltip: true
        },
        {
          label: '审核状态',
          prop: 'isReg',
          width: 100,
          align: 'center',
          colors: ['danger', 'success'],
          formatter(row) {
            return row.isReg == 1 ? '已审核' : '未审核';
          }
        },
        {
          label: '管理员',
          prop: 'isAdmin',
          width: 70,
          align: 'center',
          colors: ['danger', 'success'],
          formatter(row) {
            return row.isAdmin == 1 ? '是' : '否';
          }
        },
        {
          label: '金额',
          prop: 'balance',
          width: 80,
          align: 'center',
          showOverflowTooltip: true
        },
        {
          label: '补贴金额',
          prop: 'subsidyMoney',
          width: 80,
          align: 'center',
          showOverflowTooltip: true
        },
        {
          label: '超市支付最大限额',
          prop: 'supermarketPayMaxLimit',
          width: 80,
          align: 'center',
          showOverflowTooltip: true
        },
        {
          label: '超市支付使用最大限额',
          prop: 'supermarketPayUse',
          width: 80,
          align: 'center',
          showOverflowTooltip: true
        },
      ];
    }
  }
};
