export default {
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '120px',
        igrone: ['isActive'],
        model: this.getToolbarModel(),
        fields: this.getToolbarFields()
      }
    };
  },
  methods: {
    getToolbarModel() {
      return {
        name: undefined,
        depid: undefined,
        depids: [],
        phone_username: undefined,
        cardno: undefined,
        isReg: undefined,
        isActive: 1
      };
    },
    getToolbarFields() {
      return [
        {
          label: '姓名',
          prop: 'name',
          clearable: true,
          placeholder: '请输入要搜索的姓名'
        },
        {
          label: '部门',
          //prop: 'depid',
          prop: 'depids',
          placeholder: '请选择要搜索的部门',
          type: 'cascader',
          clearable: true,
          collapseTags: true,
          filterable: true,
          props: {
            expandTrigger: 'hover',
            emitPath: false,
            multiple: true,
            label: 'name',
            value: 'id'
          },
          showAllLevels: false,
          options: [],
          url: '/department/getList',
          method: 'post'
        },
        {
          label: '手机号或用户名',
          prop: 'phone_username',
          clearable: true,
          placeholder: '请输入要搜索的手机号或用户名'
        },
        {
          label: '身份证号或警号',
          prop: 'cardno',
          clearable: true,
          placeholder: '请输入要搜索的身份证号或警号'
        },
        {
          label: '是否审批',
          prop: 'isReg',
          type: 'select',
          clearable: true,
          options: [
            { label: '全部', value: undefined },
            { label: '已审批', value: 1 },
            { label: '未审批', value: 0 }
          ]
        }
      ];
    }
  }
};
