<template>
  <app-page ref="page" :toolbar="toolbar" :list="list" :updater="updater">
    <template #actions="{ row }">
      <el-dropdown @command="onCammand(row, $event)">
        <el-button type="text">
          更多操作
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="onAudit">审核</el-dropdown-item>
          <el-dropdown-item divided></el-dropdown-item>
          <el-dropdown-item command="onUpdating">编辑</el-dropdown-item>
          <el-dropdown-item command="onRemove">删除</el-dropdown-item>
          <el-dropdown-item divided></el-dropdown-item>
          <el-dropdown-item command="onAdmin">设置为管理员</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
  </app-page>
</template>

<script>
import request from '@/utils/request';
import mixins from './mixins';

export default {
  name: 'User',
  mixins,
  methods: {
    // 下拉操作
    async onCammand(row, e) {
      await this[e](row);
    },
    // 审核
    async onAudit(row) {
      const { $confirm, $refs } = this;
      try {
        await $confirm('确认要审核吗?', '提示', {
          type: 'warning'
        });
        await request.post('/user/auditUser', {
          id: row.userid,
          status: 1
        });
        await $refs.page.getItems();
      } catch (error) {
        console.error(error);
      }
    },
    // 编辑
    onUpdating(row) {
      const { updater, $refs } = this;
      updater.model = row;
      $refs.page.onUpdating();
    },
    // 删除
    async onRemove(row) {
      const { $confirm, $refs } = this;
      try {
        await $confirm('确认要删除用户吗?', '提示', {
          type: 'error'
        });
        await request.post('/user/del', {
          ids: [row.userid]
        });
        await $refs.page.getItems();
      } catch (error) {
        console.error(error);
      }
    },
    // 设置为管理员
    async onAdmin(row) {
      const { $confirm, $refs } = this;
      try {
        await $confirm('确认要设置为管理员吗?', '提示', {
          type: 'warning',
          cancelButtonText: '否',
          confirmButtonText: '是'
        });
        await request.post('/user/updateIsAdmin', undefined, {
          params: { id: row.userid, isAdmin: 1 }
        });
      } catch (error) {
        await request.post('/user/updateIsAdmin', undefined, {
          params: { id: row.userid, isAdmin: 0 }
        });
        console.error(error);
      } finally {
        await $refs.page.getItems();
      }
    }
  }
};
</script>
