export default {
  data() {
    return {
      updater: {
        width: '500px',
        title: '更新用户',
        text: '更新用户',
        size: 'small',
        labelWidth: '120px',
        submit: true,
        url: '/user/updateUser',
        custom(model) {
          model.id = model.userid;
          return model;
        },
        model: this.getUpdaterModel(),
        rules: this.getUpdaterRules(),
        fields: this.getUpdaterFields()
      }
    };
  },
  methods: {
    getUpdaterModel() {
      return {
        name: undefined,
        departId: undefined,
        telephone: undefined,
        idCard: undefined,
        policeCard: undefined,
        sex: undefined,
        roleId: undefined
      };
    },
    getUpdaterRules() {
      return {
        name: { required: true, message: '姓名是必填的' },
        departId: { required: true, message: '部门是必选的' },
        telephone: { required: true, message: '手机号是必填的' },
        idCard: { required: true, message: '身份证号是必填的' },
        sex: { required: true, message: '性别是必选的' },
        roleId: { required: true, message: '角色是必选的' }
      };
    },
    getUpdaterFields() {
      return [
        { label: '姓名', prop: 'name', placeholder: '请输入姓名' },
        {
          label: '部门',
          prop: 'departId',
          placeholder: '请选择部门',
          type: 'cascader',
          clearable: true,
          collapseTags: true,
          filterable: true,
          props: {
            expandTrigger: 'hover',
            checkStrictly: true,
            emitPath: false,
            label: 'name',
            value: 'id'
          },
          showAllLevels: false,
          options: [],
          url: '/department/getList',
          method: 'post'
        },
        { label: '手机号', prop: 'telephone', placeholder: '请输入手机号' },
        { label: '身份证号', prop: 'idCard', placeholder: '请输入身份证号' },
        {
          label: '警号',
          prop: 'policeCard',
          placeholder: '请输入警号，选填'
        },
        {
          label: '性别',
          prop: 'sex',
          placeholder: '请输入性别',
          type: 'select',
          clearable: true,
          options: [
            { label: '男', value: 1 },
            { label: '女', value: 2 }
          ]
        },
        {
          label: '角色',
          prop: 'roleId',
          placeholder: '请选择角色',
          type: 'select',
          clearable: true,
          options: [],
          url: '/role/getList',
          method: 'post',
          data: {
            startPage: 1,
            pageSize: 500
          },
          mapper(item) {
            return {
              label: item.name,
              value: item.id
            };
          },
          returnKey: 'records'
        },
        {
          label: '审核状态',
          prop: 'isReg',
          placeholder: '请选择状态',
          type: 'select',
          clearable: true,
          options: [
            { label: '未审核', value: 0 },
            { label: '已审核', value: 1 }
          ]
        },
      ];
    }
  }
};
